import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Box, Heading } from "@chakra-ui/core"

const Header = ({ siteTitle }) => (
  <Box marginBottom={8} borderBottom="1px solid" borderBottomColor="gray.700">
    <div
      style={{
        margin: "0 auto",
        maxWidth: 1280,
        paddingTop: 12,
        paddingBottom: 12,
      }}
    >
      <Link
        to="/"
        style={{
          color: "white",
          textDecoration: "none",
        }}
      >
        <Heading color="gray.300" marginBottom={0}>
          {siteTitle}
        </Heading>
      </Link>
    </div>
  </Box>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
}

export default Header
