import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider, ColorModeProvider, Box } from "@chakra-ui/core"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider>
      <ColorModeProvider value="dark">
        <Box height="100vh" backgroundColor="gray.800" as="main">
          <Header siteTitle={data.site.siteMetadata.title} />
          <Box margin="0 auto" width={1260}>
            {children}
          </Box>
        </Box>
      </ColorModeProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
